import React, { Component } from 'react'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import Row from '../components/page-content-row'
import Title from '../components/page-title'
import styled from 'styled-components'
import Image400 from '../components/page-image-400'
import { Link } from 'gatsby'
import { theme } from '../util/style'

const ogImages = []

const IndexItem = ({ image, title, url }) => {
  return (
    <IndexItemWrapper>
      <Link to={url}>
        <Image400 src={image} ratio={9 / 16} />
        <span>{title}</span>
      </Link>
    </IndexItemWrapper>
  )
}

export default class ForSale extends Component {
  _getGalleryThumbs() {}

  render() {
    return (
      <>
        <SEO title="For Sale" description="" images={ogImages} />
        <Row>
          <Flex flexWrap="wrap" flexDirection={['column-reverse', 'row']}>
            <Box width={[1, 9 / 12]} px={2}>
              <Title grey align="left">
                For Sale
              </Title>
            </Box>
          </Flex>
        </Row>

        <Row>
          <IndexWrapper px={2}>
            <IndexItem
              title={'Haus Tarragindi - SOLD'}
              image={
                'for-sale/happyhaus-gallery-2023-02-27-render-tarragindi-001.jpg'
              }
              url={'/for-sale-haus-tarragindi'}
            />
            <IndexItem
              title={'Haus Peary - SOLD'}
              image={'for-sale/haus-peary.jpg'}
              url={'/for-sale-haus-peary'}
            />
          </IndexWrapper>
        </Row>
      </>
    )
  }
}

const IndexWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`
const IndexItemWrapper = styled.div`
  margin-bottom: 1.2em;
  cursor: pointer;
  font-size: 95%;
  img {
    display: block;
    max-width: 100%;
  }
  a {
    display: block;
    color: ${theme.colors.grey1};
    position: relative;
    span {
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      padding: 20px;
      color: ${theme.colors.white};
    }
    &:hover {
      span {
        opacity: 1;
      }
      img {
        opacity: 0.9;
      }
    }
  }
`
